
export default {
	name: 'statistics1',
	data() {
		return {
		}
	},

	mounted() {
		this.drawLine();
		let echar1 = this.$echarts.init(document.getElementById('echar-1'));
		var _this = this;
		echar1.on('click', function (e) {
			_this.$store.commit('newResult', e.name);
			_this.$router.push('/officeProject');
		})
	},
	created() {
		var _this = this;
		this.token = this.getCookie('token')
		this.userId = this.getCookie('userId')
	},
	methods: {
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			let echar1 = this.$echarts.init(document.getElementById('echar-1'))
			// 绘制图表
			_this.$http.post('/API/PLAT/inCountDepartmentAll', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						_this.x.push(response.data.data.x);
					//						_this.y.push(response.data.data.y)
					//						console.log(_this.x)
					echar1.setOption({
						title: {
							text: '科室CRC和项目数分布',
							x: 'center',
							align: 'right'
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: { // 坐标轴指示器，坐标轴触发有效
								type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
							}
						},
						legend: {
							data: ['人员数', '项目数'],
							x: 'left'
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: response.data.data.x,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						yAxis: {
							type: 'value',
							min: 0,
							max: 40,
							interval: 10,
							boundaryGap: [0, 0.01],
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						series: [{
							name: '人员数',
							type: 'bar',
							barWidth: '25%',
							color: '#61C7D7',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.y1
						},
						{
							name: '项目数',
							type: 'bar',
							barWidth: '25%',
							color: '#FFE188',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.y2
						}
						]
					});
				}
			})

		},
		toPl() {
			this.$router.push('/statistics');
		},
	}
}
//	var echart

